import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Centralizing Communications In Leadership`}</h1>
    <div style={{
      "position": "relative",
      "paddingBottom": "56.25%",
      "height": "0"
    }}><iframe src="https://www.loom.com/embed/5cf4a9dfffaa48b786b538b991e05ccc" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></div>
    <p>{`There are so many leadership groups on campus: ASB, Student Forum, Class councils, Club leaders, Sports/Arts captains/leaders, and more! We've seen that in the COVID-19 pandemic it is so much harder to communicate with each other digitally. We used to go over to their classes or meeting times during periods to ask questions and get information. Obviously this was much harder virtually. Next year, I propose adopting a communications platform for school leaders to use to collaborate with one another.`}</p>
    <p><a parentName="p" {...{
        "href": "http://slack.com"
      }}>{`Slack`}</a>{` and `}<a parentName="p" {...{
        "href": "http://discord.com"
      }}>{`Discord`}</a>{` are two very popular server based communication systems. Currently, different groups use different methods to work with each other. ASB uses GroupMe, I used Instagram when I was on Freshman Class Council, and we use emails in the Wellness Committee. There are so many things going on and different ways we all talk with each other using the internet. I propose simplifying communication by adopting Slack for student leadership purposes. ASB would have a channel, Student Forum officers would have a channel, Club officers would have a channel, all the sports captains would have a channel as well. `}</p>
    <p>{`This would allow for more collaboration. We can have a channel where people can post events they're thinking of running and another club can jump in saying they're doing something similar and can work together. We can promote all sports equally by asking captains when they're games actually are, because sometimes the written schedule isn't always accurate.`}</p>
    <p>{`One thing I learned from YAT is that having a centralized communication system makes life so much easier. Before I introduced Slack, we used to have 2-3 GroupMe chats for different events and mini-groups, 2-3 text message chats that were made on a whim, and at least 1 email chain. When we adopted Slack, we moved our 60 person leadership team, gave every group a Slack channel, and it made things move quicker through the pipelines. Another benefit of Slack is that because some team members didn't have phones, they were able to create Slack accounts on their computer and tablets! Slack is available on every type of device, which makes it one of the best platforms for communication.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      